<template>
  <div class="login-wrapper">
    <v-container class="pa-0 ma-0 login-container" fluid>
      <v-row no-gutters class="login-row align-center justify-center">
        <v-col cols="12" sm="10" md="8" lg="6" xl="4">
          <v-card class="login-card" elevation="10">
            <v-row no-gutters style="height: 100%">
              <!-- Branding Section -->
              <v-col cols="12" md="5" class="branding-section">
                <div class="branding-overlay"></div>
                <div
                  class="d-flex flex-column justify-center align-center h-100 pa-6 relative"
                >
                  <v-img
                    :src="weblogo"
                    contain
                    height="80"
                    max-width="160"
                    class="mb-6"
                  ></v-img>
                  <h1 class="text-h4 font-weight-bold mb-4 white--text text-center">
                    AI-Pos
                  </h1>
                  <p class="subtitle-1 white--text text-center">
                    Sistema de punto de venta inteligente
                  </p>
                  <p class="mb-2 subtitle-1 white--text text-center">
                    ¿No tiene una cuenta?
                  </p>
                  <div class="text-center">
                    <v-btn outlined color="white" @click="sign_dialog = !sign_dialog">
                      Crear Cuenta
                    </v-btn>
                  </div>
                </div>
              </v-col>

              <!-- Login Form Section -->
              <v-col cols="12" md="7" class="login-form-section">
                <v-card-text class="pa-8">
                  <h2 class="text-h5 font-weight-bold mb-6">Iniciar Sesión</h2>
                  <v-form ref="form" v-model="valid" @submit.prevent="login">
                    <v-text-field
                      v-if="!storelst.length > 0"
                      v-model="credential.user_email"
                      :rules="emailRules"
                      label="Correo electrónico"
                      prepend-inner-icon="mdi-email-outline"
                      outlined
                      dense
                      class="mb-4"
                      hide-details
                    ></v-text-field>

                    <v-text-field
                      v-if="!storelst.length > 0"
                      v-model="credential.user_password"
                      :rules="f_required"
                      label="Contraseña"
                      prepend-inner-icon="mdi-lock-outline"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      outlined
                      hide-details
                      dense
                      class="mb-4"
                    ></v-text-field>

                    <v-select
                      v-if="storelst.length > 0"
                      v-model="credential.store"
                      :items="storelst"
                      label="Centro de Producción"
                      item-text="name"
                      item-value="store_id"
                      outlined
                      dense
                      class="mb-6"
                    ></v-select>

                    <v-btn
                      block
                      x-large
                      color="primary"
                      type="submit"
                      @click="validate"
                      :loading="loading_status"
                      class="mb-4 white--text"
                      elevation="2"
                    >
                      Ingresar
                    </v-btn>

                    <!-- <div class="d-flex justify-space-between align-center mb-4">
                      <v-checkbox
                        v-model="rememberMe"
                        label="Recordarme"
                        class="mt-0"
                        hide-details
                      ></v-checkbox>
                      <v-btn text color="primary" @click="forgotPassword" class="px-0">
                        ¿Olvidó su contraseña?
                      </v-btn>
                    </div> -->

                    <!-- <v-divider class="mb-4"></v-divider> -->
                  </v-form>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Floating help button -->
    <!-- <v-btn
      color="secondary"
      fab
      small
      fixed
      bottom
      right
      @click="dialog = true"
      class="mb-4 mr-4 elevation-2"
    >
      <v-icon>mdi-help-circle-outline</v-icon>
    </v-btn> -->

    <!-- AI Agent Dialog -->
    <ai_agent :dialog="dialog" @close="dialog = false" />

    <!-- Sign In Dialog -->
    <SignIn :dialog="sign_dialog" @close="sign_dialog = !sign_dialog" />

    <!-- Footer -->
    <v-footer absolute class="pa-4 login-footer">
      <v-row justify="center" align="center" no-gutters>
        <v-col cols="auto" class="text-center">
          <span class="caption"
            >&copy; {{ new Date().getFullYear() }} NGM Corp. Todos los derechos
            reservados.</span
          >
          <v-btn text x-small href="/terminos" class="px-2 ml-2">Términos</v-btn>
          <v-btn text x-small href="/privacidad" class="px-2">Privacidad</v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createProfile from "../utils/createProfile.js";
import createStore from "../utils/createStore.js";
import createUser from "../utils/createParty.js";
import ai_agent from "../components/ai/ai_agent_claude.vue";
import SignIn from "./SignIn.vue";

export default {
  name: "Login",
  components: { ai_agent, SignIn },
  props: {
    nextUrl: {
      default: null,
    },
  },
  data() {
    return {
      sign_dialog: false,
      dialog: false,
      weblogo: null,
      valid: false,
      loading_status: false,
      showPassword: false,
      rememberMe: false,
      credential: {
        store: null,
        user_email: null,
        user_password: "",
        token: null,
      },
      storelst: [],
      profile: createProfile(),
      company: createStore(),
      user: createUser(),
      emailRules: [
        (v) => !!v || "El correo electrónico es requerido",
        (v) => /.+@.+\..+/.test(v) || "El correo electrónico debe ser válido",
      ],
      f_required: [(v) => !!v || "Este campo es requerido"],
    };
  },
  beforeMount() {
    const website = this.$store.getters.getHost;
    const root = website.replaceAll(".", "");
    this.weblogo = `./sites/${root}/logo.webp`;
  },
  mounted() {
    this.$store.dispatch("setNavStatus", false);
    this.$store.dispatch("set_menu", false);
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    login(e) {
      e.preventDefault();
      if (this.validate()) {
        this.loading_status = true;
        const md5 = require("md5");
        const qry = {
          user_email: this.credential.user_email,
          user_password: md5(this.credential.user_password),
          user_token: this.credential.token,
          account: this.company.account,
          store: this.credential.store,
        };
        const metodo = this.credential.store ? "login" : "pre_login";

        webserver(metodo, qry, (data) => {
          this.loading_status = false;
          if (metodo === "login") {
            this.handleSuccessfulLogin(data);
          } else {
            this.handlePreLogin(data);
          }
        });
      }
    },
    handleSuccessfulLogin(data) {
      this.profile = { ...this.profile, ...data.profile };

      this.$store.dispatch("setProfile", this.profile);
      this.$store.dispatch("setCompany", data.company);

      window.token = data.token;
      window.profile = this.profile;
      window.company = data.company;
      window.user = data.user;

      this.encryptAndStoreData("sc_c", this.profile);
      this.encryptAndStoreData("sc_s", data.company);

      this.$store.dispatch("setNavStatus", true);
      this.$store.dispatch("set_menu", true);

      this.$router.push(this.nextUrl || "/Welcome");
    },
    handlePreLogin(data) {
      if (data.length === 0) {
        this.showErrorToast("Credenciales inválidas");
      } else if (data.length > 1) {
        this.storelst = data;
      } else if (data.length === 1) {
        this.credential.store = data[0].store_id;
        this.login(new Event("submit"));
      }
    },
    encryptAndStoreData(key, data) {
      const encryptedText = this.CryptoJS.AES.encrypt(
        JSON.stringify(data),
        process.env.VUE_APP_QR4
      ).toString();
      window.localStorage.setItem(key, encryptedText);
    },
    showErrorToast(message) {
      this.$store.dispatch("setToast", {
        timeout: 3000,
        text: message,
        color: "error",
      });
    },
    forgotPassword() {
      // Implementar lógica de recuperación de contraseña
      console.log("Recuperación de contraseña solicitada");
    },
  },
  watch: {
    "$store.getters.getManifest"(newVal) {
      if (newVal) {
        this.manifest = newVal;
      }
    },
    "$store.getters.company"(newVal) {
      if (newVal) {
        this.company = newVal;
      }
    },
  },
};
</script>

<style scoped>
.login-wrapper {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.login-container {
  flex-grow: 1;
  display: flex;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.login-row {
  width: 100%;
}

.login-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  overflow: hidden;
}

.branding-section {
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
  position: relative;
  overflow: hidden;
}

.branding-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
}

.branding-section::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 10%, transparent 10.5%);
  background-size: 20px 20px;
  transform: rotate(30deg);
  animation: moveBackground 60s linear infinite;
}

.relative {
  position: relative;
  z-index: 1;
}

@keyframes moveBackground {
  0% {
    transform: translate(0, 0) rotate(30deg);
  }
  100% {
    transform: translate(-58px, -58px) rotate(30deg);
  }
}

.login-form-section {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.login-footer {
  width: 100%;
  z-index: 1;
}

@media (max-width: 959px) {
  .branding-section {
    min-height: 200px;
  }
}
</style>
